import React from "react";
import * as THREE from "three";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import SceneImg from "../img/scene.png";

class Scene extends React.Component {
    uMouse = null
    requestAnimationId = null

    mouseMoveHandler = (e) => {
        this.uMouse.x = ( e.clientX / window.innerWidth ) ;
        this.uMouse.y = 1. - ( e.clientY/ window.innerHeight );
    }

    componentDidMount() {
        let texture, camera, scene, customPass, composer, geometry, renderer;
        this.uMouse = new THREE.Vector2(0, 0);

        // const img = document.querySelector('.js-webgl-img');
        const dummyImg = document.createElement('img');

        const self = this;

        dummyImg.onload = function () {
            // self.img.style.opacity = 0;
            texture = new THREE.Texture(this);
            texture.needsUpdate = true;
            init();
            self.requestAnimationId = requestAnimationFrame(animate);
        };

        dummyImg.src = this.img.src;

        function init() {
            camera = new THREE.PerspectiveCamera( 25, window.innerWidth / window.innerHeight, 0.01, 10 );
            camera.position.z = 0.5;

            scene = new THREE.Scene();

            geometry = new THREE.PlaneGeometry(0.45, 0.3);

            const material = new THREE.MeshBasicMaterial({
                map: texture
            });

            const mesh = new THREE.Mesh(geometry, material);
            scene.add(mesh);

            renderer = new THREE.WebGLRenderer({ antialias: true });
            renderer.setSize(window.innerWidth, window.innerHeight);
            renderer.outputEncoding = THREE.sRGBEncoding;

            if (self.mount) {
                self.mount.append(renderer.domElement);
            }

            // post processing
            composer = new EffectComposer(renderer);
            const renderPass = new RenderPass(scene, camera);
            composer.addPass(renderPass);

            const myEffect = {
                uniforms: {
                    tDiffuse: { value: null },
                    resolution: { value: new THREE.Vector2(1., window.innerHeight / window.innerWidth) },
                    uMouse: { value: new THREE.Vector2(-10, -10) },
                    uVelo: { value: 0 },
                    uWaveZ: { value: 0 },
                    uTime: { value: 0 },
                    uRandom1: { value: random1.get() },
                    uRandom2: { value: random2.get() },
                },
                vertexShader: `
                    varying vec2 vUv;

                    void main() {
                        vUv = uv;
                        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
                    }
                    `,
                fragmentShader: `
                    uniform sampler2D tDiffuse;
                    uniform vec2 resolution;
                    varying vec2 vUv;
                    uniform vec2 uMouse;

                    // wave
                    float speed = 0.02;
                    uniform float uRandom1;
                    uniform float uRandom2;
                    uniform float uTime;


                    float circle(vec2 uv, vec2 disc_center, float disc_radius, float border_size) {
                        uv -= disc_center;
                        uv*=resolution;
                        float dist = sqrt(dot(uv, uv));
                        return smoothstep(disc_radius+border_size, disc_radius-border_size, dist);
                    }
                    void main()  {
                        vec2 newUV = vUv;
                        float amplitudeHor = 0.01 + (((uRandom1 - 0.5) * newUV.y) / 20.0);
                        float frequencyHor =  50.0 * ((uRandom2 - 0.5) * newUV.y * 2.0);

                        float amplitudeVert = 0.05 + (((uRandom2 - 0.5) * newUV.x) / 15.0);
                        float frequencyVert = 50.0 * ((uRandom1 - 0.5) * newUV.x * 2.0);

                        float c = circle(vUv, uMouse, 0.0, 0.2);

                        float distortionVert = sin(newUV.y * frequencyVert + uTime * speed) * amplitudeVert;
                        float distortionHor = sin(newUV.x * frequencyHor + uTime * speed) * amplitudeHor;

                        newUV.x = newUV.x + distortionHor;
                        newUV.y = newUV.y + distortionVert;

                        vec2 rCoord = newUV.xy += c * (0.1 * .5);
                        vec2 gCoord = newUV.xy += c * (0.1 * .525);
                        vec2 bCoord = newUV.xy += c * (0.1 * .55);

                        float r = texture2D(tDiffuse, rCoord).x;
                        float g = texture2D(tDiffuse, gCoord).y;
                        float b = texture2D(tDiffuse, bCoord).z;
                        vec4 color = vec4(r, g, b, 1.);
                        gl_FragColor = color;
                    }
                `
            };

            customPass = new ShaderPass(myEffect);
            customPass.renderToScreen = true;
            composer.addPass(customPass);
        }

        document.addEventListener('mousemove', this.mouseMoveHandler);

        // for wave
        class SmoothRandom {
            constructor(step) {
                this.step = step;
                this.from = +Math.random().toFixed(4);
                this.to = +Math.random().toFixed(4);
            }

            get() {
                this.update();
                return this.from;
            }

            update() {
                const {from, to, step} = this;

                if (from > to + step) {
                    this.from -= step;
                } else if (from < to - step) {
                    this.from += step
                } else {
                    this.to = +Math.random().toFixed(4);
                }
            }
        }

        const fps = 60;
        const frameDuration = 1000 / fps;
        let time = 0;
        let lastTime = 0; 

        const random1 = new SmoothRandom(0.0003);
        const random2 = new SmoothRandom(0.0001);

        function animate(elapsed) {
            customPass.uniforms.uMouse.value = self.uMouse;
            customPass.uniforms.uTime.value = getTime(elapsed);

            customPass.uniforms.uRandom1.value = random1.get();
            customPass.uniforms.uRandom2.value = random2.get();

            self.requestAnimationId = requestAnimationFrame(animate);
            composer.render();
        }

        function getTime(elapsed) {
            const delta = elapsed - lastTime;
            lastTime = elapsed;

            const step = delta / frameDuration;
            time += step;

            return time;
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousemove', this.mouseMoveHandler);
        cancelAnimationFrame(this.requestAnimationId);
    }

    render() {
        return (
            <div ref={ ref => (this.mount = ref) } id="appContainer" className="scene">
                <img alt="" ref={ ref => (this.img = ref) } src={ SceneImg } />
            </div>
        )
    }
}

export default Scene;