import React, { useEffect, useState } from "react";

import "../scss/main.scss";
import FAQItem from "../components/faqItem";
import scrollTo from "gatsby-plugin-smoothscroll";
import Scroller from "../svg/scroller.svg";

const FAQ = ({faqList}) => {
    const [openedId, setOpenedId] = useState(null);

    function handleClick(id) {
        id === openedId ? setOpenedId(null) : setOpenedId(id);
    }

    function handleClickOutside(e) {
        const target = e.target.closest('.js-faq-item');
        if(target) return

        setOpenedId(null);
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, [])

    return (
    <>
        <div className="faq" id="faq">
            <div className="container">
                <h1 className="faq__title">OUR SOLUTIONS OFFER THE LATEST IN ENGAGING TECHNOLOGIES</h1>
                <div className="faq__text" style={ { "--width": "60%" }}>
                    <p>From AI-driven exhibits that adapt to participants, to LED wall art that enlivens spaces, and interactive journeys that empower audiences, our creations are a testament to our dedication to pioneering the intersections of art and technology.</p>
                </div>
                <ul className="faq__list">

                    {faqList.map(({question, answer, id}) => (
                        <FAQItem
                            key={id}
                            id={id}
                            question={question}
                            answer={answer}
                            handleClick={handleClick}
                            isOpen={openedId === id}
                        />
                    ))}

                </ul>

                <button className="scroller site-section__scroller" type="button" onClick={ () => scrollTo("#join-teleport") }>
                    <Scroller className="scroller__arrow" />
                </button>
            </div>
        </div>
    </>
    )
}

export default FAQ;
