import React from 'react';
import TriangleDown from "../svg/triangle-down.svg";


const FAQItem = (props) => {
    const {id, handleClick, question, answer, isOpen} = props;

    return (
        <li className={`faq__item js-faq-item ${isOpen ? '_open' : ''}`}>
            <div className="faq__item-inner">
                <button className="faq__toggle" type="button" onClick={() => handleClick(id)}>
                    <span>{question}</span>
                    <TriangleDown className="faq__toggle-icon"/>
                </button>
                <div 
                    className={`faq__dropdown`}
                    dangerouslySetInnerHTML={{__html: answer}}
                >
                    {/* <p className="faq__dropdown-text">{answer}</p> */}
                </div>
            </div>
        </li>
    )
}

export default FAQItem